import { useEffect, useContext, useState } from "react";
import {TiExport} from "react-icons/ti";
import { useSelector, useDispatch } from 'react-redux';
import {BsChevronLeft, BsChevronRight, BsChevronUp, BsChevronDown} from "react-icons/bs";
import { TodaysTransactionsAction } from "../actions/Admin/dashboard/TodaysTransactionsAction";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable"

import { AppContext } from "../AppContext";
import "../datatable.css"

const DataTable = () => {
    const dispatch = useDispatch()
    const {todays_transactions_error,todays_transactions,todays_transactions_docs} = useSelector(state => state.todaysTransactionsReducer);
    const [preShipmentItems, setPreShipmentItems] = useState([]);
    const [todaysTransaction, setTodaysTransaction] = useState([]);
    const [numItems, setNumItems] =useState(20)
    const [itemId, setItemId] = useState("");
    const [expandDetails, setExpandDetails] = useState("");
    const {selectedTransaction,toggleTransactions} = useContext(AppContext)
    const format = new Intl.NumberFormat('en-US');
   
    const data = preShipmentItems && preShipmentItems.filter(item => item._id === itemId && itemId)[0];
    const trackingId = todaysTransaction && todaysTransaction.way_bill;
    const textStyle = data && data.status === "accepted" ? "text-delivered" : "text-rejected";

    const expandTransactions = (index) => {
        if(expandDetails === index){
            return setExpandDetails(null)
        }
        setExpandDetails(index)
    }
    const columns = [
        {title: "Transaction id", field: "Transaction id"}, {title: "Waybill No", field: "Waybill No"},{title: "Buyer", field: "Buyer"},{title: "Merchant", field: "Merchant"},{title: "Price", field: "Price"},{title: "Shipping fee", field: "Shipping fee"},{title: "Payment method", field: "Payment method"},{title: "Purchased from", field: "Purchased from"},{title: "Date", field: "Date"}
    ];
    const getData = todays_transactions_docs && todays_transactions_docs.map(({_id,ReceiverName, SenderName,totalCost, ShippingFee,IsCashOnDelivery,device_platform, createdAt,way_bill

    }) => {
        const methodOfPayment = IsCashOnDelivery ?  "Cash on delivery" : "Paystack";
        const date = moment(createdAt).format("D/MM/YYYY h:mm a")
        return [_id,way_bill,ReceiverName,SenderName,totalCost,ShippingFee, methodOfPayment,device_platform, date]
    });

    
   const downloadPdf = () => {
      const doc = new jsPDF();
      let str = "Today's transactions";
      let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.text(str, pageWidth / 2, 10, {align: 'center'});
      doc.autoTable({
         columns: columns.map((col) => ({...col, dataKey: col.field})),
         body: getData && getData
      })
      doc.save(`today's-transactions-${new Date()}.pdf`)
   }
    useEffect(() => {
        const offset = 1;
          const limit = numItems;
          const data = {offset, limit}
        dispatch(TodaysTransactionsAction(data))
    },[dispatch,numItems]);

    const handleNextPage = (page_number) => {
        const offset = page_number;
        const limit = numItems;
        const data = { offset, limit };
        dispatch(TodaysTransactionsAction(data));
    };
    const totalPages = todays_transactions && todays_transactions.totalPages;
    const page = todays_transactions && todays_transactions.page;
    const prevPage = todays_transactions && todays_transactions.prevPage;
    const nextPage = todays_transactions && todays_transactions.nextPage;

    console.log("data", todays_transactions_docs)
     
    return (
        <>
        <div className="card card_wrapper">
            <div className="data_heading">
            <div className="header_order_title">TODAY’S TRANSACTION</div>
            {
               todays_transactions_docs.length > 0 && <button className="button_export" onClick={downloadPdf}><TiExport />&nbsp; <span>Export</span></button>
            }
        
            </div>
        <div className="card-body sub-category-content-body">
               {todays_transactions_error && 
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Error!</h4>
                        <hr/>
                        <p className="mb-0">{todays_transactions_error || todays_transactions_error.message }</p>
                    </div>
                }
                {todays_transactions_docs && todays_transactions_docs.length > 0 ?   todays_transactions_docs.map((item, index) => (
                         <div key={item._id}>
                         <div className="todays_order_content">
                             <div className="todays_ordered_prod_name"><strong>Transaction id:</strong> {item._id}</div>
                             <div className="todays_ordered_prod_name"><strong>Buyer: </strong>{item.ReceiverName}</div>
                             <div className="todays_ordered_prod_name"><strong>Merchant: </strong>{item.SenderName}</div>
                             <div className="todays_ordered_prod_name"><strong>Total price: </strong>&#x20A6;{format.format(item?.totalCost + item?.ShippingFee)}</div>
                             <div className="todays_ordered_prod_name"><strong>Payment method: </strong>{item.IsCashOnDelivery ? "Cash on delivery" : "Cash"}</div>
                             <div className="todays_ordered_prod_name">{moment(item.createdAt).format("D/MM/YYYY h:mm a")}</div>
                             {selectedTransaction === index ?  <BsChevronUp onClick={() => toggleTransactions(index)}/> : <BsChevronDown onClick={() => {toggleTransactions(index); setExpandDetails(""); setPreShipmentItems(item?.PreShipmentItems); setTodaysTransaction(item); setExpandDetails("")}}/> }
                         </div>
                         <div className={selectedTransaction  === index ? "todays_order_list_content show" : "todays_order_list_content"} >
                                 
                             {
                                preShipmentItems && preShipmentItems.map((item,index) => (
                                    <>
                                    <div key={item._id + index}>
                                        <div className="todays_order_content">
                                            <div className="todays_ordered_prod_name"><div>Product Name</div> <div>{item && item.ItemName}</div></div>
                                            <div className="todays_ordered_prod_name"><div>Category</div><div> {item.category}</div></div>
                                            <div className="todays_ordered_prod_name"><div>Quantity</div><div>{item.Quantity}</div></div>
                                            
                                            <div className="todays_ordered_prod_name"><div>Store Name</div> <div>{item.store_name}</div></div>
                                            <div className="todays_ordered_prod_name"><div>Client Name</div> <div>{todaysTransaction && todaysTransaction.ReceiverName}</div></div>
                                            {
                                                expandDetails === index ? <button className="view_details" onClick={() => {expandTransactions(index);}}>Minimize</button> : <button className="view_details" onClick={() => {expandTransactions(index); setItemId(item._id)}}>View details</button>
                                            }
                                            
                                        </div>
                                        <div className={expandDetails  === index ? "todays_order_list_content todays_order_list_content_details show" : "todays_order_list_content todays_order_list_content_details"} >
                                        <div className="card">
                                           <div className="card-body">
                                             <div className="row">
                                             <div className="col-4 col-lg-4">
                                                {
                                                    data && data.product_image &&
                                                
                                                       <div className=" img-col-size-sec">
                                                        <div className="image-data recent-order-img">
                                                            <img src={data && data.product_image} alt={data && data.product_image}/>
                                                        </div>
                                                        <div className="color-size-todays-sec">
                                                        {data && data.color &&                                                 
                                                            <div> 
                                                                <div className="product-color-oms">Color</div>
                                                                <div style={{backgroundColor: data.color, width: "25px", height: "25px", borderRadius: "50%", border: "1px solid #333333a0"}}><span></span></div>
                                                            </div>
                                                            }
                                                            {data && data.size && 
                                                                <div>
                                                                        <div className="product-color-oms">Size</div>
                                                                    <div style={{border: "1px solid #333333a0", width: "auto", height: "auto", borderRadius: "2px", textAlign: "center", padding: "3px"}}><span className="size-oms">{data.size}</span></div>
                                                                </div>
                                                           }
                                                        </div>
                                                    
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-8 col-lg-8">
                                                     <div className="table-responsive">
                                                      <table className="table">
                                                        <thead className="thead-light">
                                                            <tr align="middle">
                                                                <th scope="col" className="waybill-header-details">Waybill No.</th>
                                                                <th scope="col" className="waybill-header-details">Item price</th>
                                                                <th scope="col" className="waybill-header-details">Alpha code</th>
                                                                <th scope="col" className="waybill-header-details">Shipping fee</th>
                                                                <th scope="col" className="waybill-header-details">Store Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody align="middle">
                                                            <tr>
                                                                <td className="waybill-no-details">{trackingId ? trackingId : "-" }</td>
                                                                <td className="waybill-no-details">&#x20A6;{format.format(data && data.ItemValue)}</td>
                                                                <td className="waybill-no-details">{data?.alphaCode}</td>
                                                                <td className="waybill-no-details">&#x20A6;{format.format(todaysTransaction && todaysTransaction.ShippingFee)}</td>
                                                                <td className="waybill-no-details">{ data && data.store_name}</td>
                                                               
                                                            </tr>
                                                            
                                                        </tbody>
                                                      </table>
                                                      </div>
                                                      <div className="table-responsive">
                                                        <table className="table">
                                                            <thead className="thead-light">
                                                                <tr align="middle">
                                                                    <th scope="col" className="waybill-header-details">Order Qty</th>
                                                                    <th scope="col" className="waybill-header-details">Payment Type</th>
                                                                    <th scope="col" className="waybill-header-details">Order Status</th>
                                                                    <th scope="col" className="waybill-header-details">Client Name</th>
                                                                </tr>
                                                            </thead>
                                                            
                                                            <tbody align="middle">
                                                                <tr>
                                                                    <td className="waybill-no-details">{data ? data.Quantity : "-"}</td>
                                                                    <td className="waybill-no-details payment-type">{todaysTransaction  && todaysTransaction.IsCashOnDelivery? "Cash on Delivery" : "Cash"}</td>
                                                                    <td className="waybill-no-details"><span className={textStyle}>{data && data.status}</span></td>
                                                                    
                                                                    <td className="waybill-no-details">{todaysTransaction && todaysTransaction.ReceiverName}</td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                      </div>
                                                      <div className="table-responsive">
                                                         <table className="table">
                                                            <thead className="thead-light">
                                                                <tr  align="left">
                                                                    <th scope="col" className="waybill-header-details">Merchant phone No.</th>
                                                                    <th scope="col" className="waybill-header-details">Merchant email Address</th>
                                                                    <th scope="col" className="waybill-header-details">Pickup Address</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody align="middle">
                                                                <tr>
                                                                    <td className="waybill-no-details">{todaysTransaction && todaysTransaction.SenderPhoneNumber}</td>
                                                                    <td className="waybill-no-details email-address">
                                                                        {todaysTransaction && todaysTransaction.SenderEmail === "" ? "-" :
                                                                        <a href={`mailto:${todaysTransaction && todaysTransaction.SenderEmail}`}>{todaysTransaction && todaysTransaction.SenderEmail}</a>}</td>
                                                                    <td className="waybill-no-details">{todaysTransaction && todaysTransaction.SenderAddress}</td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                      </div>
                                                      <div className="table-responsive">
                                                         <table className="table">
                                                            <thead className="thead-light">
                                                                <tr  align="left">
                                                                    <th scope="col" className="waybill-header-details">Receiver phone No.</th>
                                                                    <th scope="col" className="waybill-header-details">Receiver email Address</th>
                                                                    <th scope="col" className="waybill-header-details">Delivery Address</th>
                                                                    {todaysTransaction.isDropOff &&   <th scope="col" className="waybill-header-details">Preshipment code</th>}
                                                                </tr>
                                                            </thead>
                                                            <tbody align="middle">
                                                                <tr>
                                                                    <td className="waybill-no-details">{todaysTransaction && todaysTransaction.ReceiverPhoneNumber}</td>
                                                                    <td className="waybill-no-details email-address"><a href={`mailto:${todaysTransaction && todaysTransaction.ReceiverEmail}`}>{todaysTransaction && todaysTransaction.ReceiverEmail}</a></td>
                                                                    <td className="waybill-no-details">{todaysTransaction && todaysTransaction.ReceiverAddress}</td>
                                                                    {todaysTransaction && todaysTransaction.isDropOff &&  <td className="waybill-no-details">{todaysTransaction && todaysTransaction.preShipmentCode}</td>}
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </table>
                                                      </div>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                        <hr />
                                        </div>
                                    </div>
                                    
                                 </>
                                ))
                             }
                         </div>
                         </div>
                )): <h2 className="text-center no-record-found">No Records Found</h2>

           


                                            
                                                

          
            }
              
              { totalPages > 1 ? 
                    <div className="paginate_reuse d-flex justify-content-center">
                        <button className="left-chevron" onClick={() => {handleNextPage(prevPage)}}><BsChevronLeft /></button><button className="middle-chevron">{page}</button><button className="right-chevron" onClick={() => {handleNextPage(nextPage && nextPage); }}><BsChevronRight /></button>
                    </div>: null
              }
        </div>
        </div>
      
        </>
    )
}

export default DataTable;